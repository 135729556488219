import React from 'react'
import { withAuthentication } from '../Session'

const Sbcci = (props) => {
  return (
    <div class='single_page single_post clear'>
      <header>
        <h1 className='text-center py-3 text-5xl'>Work-life balance</h1>
      </header>
      <div id='content' class='post-single-content box mark-links px-1 sm:px-2'>
        <p className='pb-3'>
          In the Sam Bingham Community Cares Initiative (SBCCI) work-life
          balance session in 2024 GEM Summer workshop, five of our colleagues
          will share their unique experience and advice on how they have managed
          a balance between work and personal life during significant life
          events including starting a family and the blurred boundaries imposed
          upon us by the Covid pandemic.{' '}
        </p>
        <p className='pb-3'>
          We welcome your opinions and questions for the panelists on this
          important mental health topic.
        </p>
      </div>{' '}
    </div>
  )
}

export default withAuthentication(Sbcci)
