import React, { useContext } from 'react'
import {
  AuthUserContext,
  withAuthentication,
} from '../Session'
import { GROUPS } from '../../constants/splinterGroups'
import AddTalkModal from '../AddTalk'
import AllBlocksComponent from './AllBlocksComponent'
import { useParams } from 'react-router-dom'
import { FirebaseContext } from '../../server/Firebase'

const SplinterGroup = (props) => {
  let { focusId } = useParams()
  const name = focusId
  const longName = GROUPS[name].longName
  const hosts = GROUPS[name].hosts
  const subcommittee = GROUPS[name].subcommittee
  const firebase = useContext(FirebaseContext)

  return (
    <div>
      <h1 className='text-4xl p-3 text-center'>{longName}</h1>
      {hosts && <p className='px-2'>Focus Group Leaders: {hosts}</p>}
      {subcommittee && <p>Subcommittee: {subcommittee.join(', ')}</p>}
      <AuthUserContext.Consumer>
        {(authUser) => {
          return (
            <div className='pt-3'>
              <AllBlocksComponent splinterGroup={name} />
            </div>
          )
        }}
      </AuthUserContext.Consumer>
    </div>
  )
}

export default withAuthentication(SplinterGroup)
