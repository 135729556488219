import React, { useEffect, useState } from 'react'
import { Select, TextField } from '@mui/material'
import { Button } from 'react-bootstrap'
import { POSTER_CATEGORIES } from '../../constants/posterSched.js'
import { useSearchParams } from 'react-router-dom'
import './filterBar.css'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import { GROUPS } from '../../constants/splinterGroups.js'
import { POSTER_TIMES } from '../../constants/posterTimes.js'

const researchAreas = Object.keys(POSTER_CATEGORIES).map((key) => {
  return { id: key, display: POSTER_CATEGORIES[key] }
})
const focusGroups = Object.keys(GROUPS)
  .filter((fg) => !['SBCCI', 'GMAG', 'SPEDAS', 'CGS'].includes(fg))
  .map((key) => {
    return { id: key, display: GROUPS[key].longName }
  })
focusGroups.push({ id: 'Other', display: 'Other' })

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const FilterBar = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const ras = [...searchParams]
    .filter((e) => e[0] === 'researchArea')
    .map((e) => e[1])
  const [chosenResearchAreas, setChosenResearchAreas] = useState(ras)
  const [presenter, setPresenter] = useState(
    searchParams.get('presenter') || ''
  )
  const [title, setTitle] = useState(searchParams.get('title') || '')
  const files = [...searchParams]
    .filter((e) => e[0] === 'hasFiles')
    .map((e) => e[1])
  const [uploadedFiles, setUploadedFiles] = useState(files)
  const fgs = [...searchParams]
    .filter((e) => e[0] === 'focusGroup')
    .map((e) => e[1])
  const [chosenFgs, setChosenFgs] = useState(fgs)
  const [selectedTimes, setSelectedTimes] = useState(
    [...searchParams].filter((e) => e[0] === 'time').map((e) => e[1])
  )

  useEffect(() => {
    const params = {}
    if (chosenResearchAreas) params.researchArea = chosenResearchAreas
    if (presenter) params.presenter = presenter
    if (uploadedFiles) params.hasFiles = uploadedFiles
    if (title) params.title = title
    if (chosenFgs) params.focusGroup = chosenFgs
    if (selectedTimes) params.time = selectedTimes

    setSearchParams(params)
  }, [
    chosenResearchAreas,
    presenter,
    uploadedFiles,
    title,
    chosenFgs,
    selectedTimes,
    setSearchParams,
  ])

  const handleClearAll = (e) => {
    setChosenResearchAreas([])
    setPresenter('')
    setUploadedFiles([])
    setTitle('')
    setChosenFgs([])
    setSelectedTimes([])
  }

  return (
    <div className='flex flex-wrap justify-center items-center'>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>Research Area</InputLabel>
        <Select
          id='researchArea'
          multiple
          labelId='researchArea-label'
          value={chosenResearchAreas}
          onChange={(e) =>
            setChosenResearchAreas(
              typeof e.target.value === 'string'
                ? e.target.value.split(',')
                : e.target.value
            )
          }
          input={<OutlinedInput label='Research Areas' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {researchAreas.map((ra) => {
            return (
              <MenuItem key={ra.id} value={ra.id}>
                <Checkbox checked={chosenResearchAreas.includes(ra.id)} />
                <ListItemText
                  primary={ra.display}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>Focus Group</InputLabel>
        <Select
          id='focusGroup'
          multiple
          labelId='focusGroup-label'
          value={chosenFgs}
          onChange={(e) =>
            setChosenFgs(
              typeof e.target.value === 'string'
                ? e.target.value.split(',')
                : e.target.value
            )
          }
          input={<OutlinedInput label='Focus Group' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {focusGroups.map((fg) => {
            return (
              <MenuItem key={fg.id} value={fg.id}>
                <Checkbox checked={chosenFgs.includes(fg.id)} />
                <ListItemText
                  primary={fg.display}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <TextField
        id='presenter'
        label='Presenter'
        variant='outlined'
        sx={{ m: 1 }}
        value={presenter}
        onChange={(e) => {
          setPresenter(e.target.value)
        }}
      />
      <TextField
        id='title'
        label='Title'
        variant='outlined'
        sx={{ m: 1 }}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value)
        }}
      />
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>Presenting Times</InputLabel>
        <Select
          id='presentingTimes'
          multiple
          labelId='presenting-label'
          value={selectedTimes}
          onChange={(e) =>
            setSelectedTimes(
              typeof e.target.value === 'string'
                ? e.target.value.split(',')
                : e.target.value
            )
          }
          input={<OutlinedInput label='Presenting Times' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {['tue', 'thu'].map((day) => {
            return (
              <MenuItem key={day} value={day}>
                <Checkbox checked={selectedTimes.includes(day)} />
                <ListItemText
                  primary={
                    day === 'tue'
                      ? 'Tuesday Poster Session'
                      : 'Thursday Poster Session'
                  }
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </MenuItem>
            )
          })}
          {Object.values(POSTER_TIMES).map((time) => {
            return (
              <MenuItem key={time.id} value={time.id}>
                <Checkbox checked={selectedTimes.includes(time.id)} />
                <ListItemText
                  primary={time.fg + ' - ' + time.day + ' ' + time.time}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, width: '10em' }}>
        <InputLabel>Uploaded Files</InputLabel>
        <Select
          id='uploaded-files'
          multiple
          labelId='uploaded-files-label'
          value={uploadedFiles}
          onChange={(e) =>
            setUploadedFiles(
              typeof e.target.value === 'string'
                ? e.target.value.split(',')
                : e.target.value
            )
          }
          input={<OutlinedInput label='Uploaded files' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          styles={{ width: 100 }}
        >
          {['PDF', 'Video'].map((fileType) => {
            return (
              <MenuItem key={fileType} value={fileType}>
                <Checkbox checked={uploadedFiles.includes(fileType)} />
                <ListItemText primary={fileType} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Button
        variant='outline-primary'
        type='reset'
        onClick={handleClearAll}
        className='h-[3em]'
      >
        Clear All
      </Button>
    </div>
  )
}
export default FilterBar
