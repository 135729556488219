export const LANDING = '/'
export const SIGN_UP = 'signup'
export const SIGN_IN = 'signin'
export const HOME = 'home'
export const ADMIN = 'admin'
export const SCHEDULE = 'schedule'
export const FOCUSGROUPS = 'groups'
export const STUDENTSCHEDULE = 'student'
export const ONEFOCUSGROUP = 'groups/:focusId'
export const PLENARY = 'plenary'
export const POSTERS = 'posters'
export const SLACK = 'slack'
export const DISCUSSION = 'discussion'
export const LOGO = 'logo'
export const SBCCI = 'sbcci'
