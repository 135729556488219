import React from 'react'
import logo from '../../imgs/cropped-GEM-Logo-Transparent_Horizontal.png'
import settingInfo from '../../imgs/right_logo-3.png'
import { NavLink } from 'react-router-dom'

const Header = () => (
  <div>
    <NavLink to='/'>
      <span className='flex w-full justify-center items-center'>
        <img
          src={logo}
          alt='GEM Logo'
          className='w-[315px] h-[115px] gap-x-1'
        ></img>
        <img
          src={settingInfo}
          alt='2024 GEM Summer Workshop. Fort Collins, CO June 23-28'
          className='hidden lg:block sm:w-[728px] sm:h-[179px]'
        ></img>
      </span>
    </NavLink>
  </div>
)

export default Header
