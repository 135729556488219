import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

const Hamburger = () =>
(<div className='group cursor-pointer w-full box-border w-max'>
{
  [...new Array(3)].map((a, i) => (
    <div className='w-8 h-1 my-2 bg-[#FFFFFF] group-hover:bg-neutral-300 rounded-sm' key={'hamburger'+i}/>
  ))
}
</div>)



export default Hamburger;
