import React from 'react'
import { NavLink } from 'react-router-dom'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

const NavDropdown = ({ title, options }) => {
  return (
    <Menu>
      <MenuButton className='flex items-center md:gap-0 lg:gap-1' key={title}>
        <div className='grow-0 inline-block shrink-1'>{title}</div>
        <ChevronDownIcon className='size-5 fill-white/60 grow-0 shrink-0' />
      </MenuButton>
      <MenuItems anchor='bottom' className='divide-y divide-blue-900'>
        {options.map((option) => (
          <NavLink
            className='block data-[focus]:bg-blue-300 bg-[#1d73be] p-2 text-white border-blue-900 first:border-t last:border-b'
            to={option.route}
            key={option.name}
          >
            <MenuItem>
              <div>{option.name}</div>
            </MenuItem>
          </NavLink>
        ))}
      </MenuItems>
    </Menu>
  )
}

export default NavDropdown
