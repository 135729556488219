import React from 'react'
import ReactDOM from 'react-dom/client'
import './gemkt.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals'
import App from './components/App'
import Firebase, { FirebaseContext } from './server/Firebase'
import * as ROUTES from './constants/routes'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import SignInPage from './components/SignIn'
import SignUpPage from './components/SignUp'
import Schedule from './components/Schedule'
import StudentDay from './components/StudentDay'
import StudentRep from './components/StudentRep'
import Plenary from './components/Plenary'
import SplinterGroups from './components/SplinterGroups'
import SplinterGroup from './components/SplinterGroup'
import Posters from './components/Posters'
import Poster from './components/Poster'
import Sbcci from './components/SBCII'
import { CodeOfConduct } from './components/CodeOfConduct'
import JennGannon from './components/JennGannon'

// const router = createBrowserRouter([
//   {
//     path: '/*',
//     element: <App />,
//     children: [
//       { path: '', element: <Schedule /> },
//       { path: ROUTES.SCHEDULE, element: <Schedule /> },
//       { path: ROUTES.SIGN_IN, element: <SignInPage /> },
//       { path: ROUTES.SIGN_UP, element: <SignUpPage /> },
//       { path: ROUTES.STUDENTSCHEDULE, element: <StudentDay /> },
//       { path: 'studentRep', element: <StudentRep /> },
//       { path: ROUTES.PLENARY, element: <Plenary /> },
//       { path: ROUTES.FOCUSGROUPS, element: <SplinterGroups /> },
//       { path: ROUTES.ONEFOCUSGROUP, element: <SplinterGroup /> },
//       { path: 'posters', element: <Posters /> },
//       { path: 'posters/:posterId', element: <Poster /> },
//     ],
//   },
// ])

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='' element={<Schedule />} />
      <Route path='schedule' element={<Schedule />} />
      <Route path='signin' element={<SignInPage />} />
      <Route path='student' element={<StudentDay />} />
      <Route path='studentRep' element={<StudentRep />} />
      <Route path='plenary' element={<Plenary />} />
      <Route path='posters' element={<Posters />} />
      <Route path='posters/:posterId' element={<Poster />} />
      <Route path='groups/:focusId' element={<SplinterGroup />} />
      <Route path='conduct' element={<CodeOfConduct />} />
      <Route path='sbcci' element={<Sbcci />} />
      <Route path='jenngannon' element={<JennGannon />} />
    </Route>
  ),
  { basename: process.env.PUBLIC_URL }
)
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <RouterProvider router={router} />
    </FirebaseContext.Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
