import React, { useState, useContext } from 'react'
import TimeAgo from 'react-timeago'
import { FirebaseContext } from '../../server/Firebase'
import { DropdownButton, Dropdown, Modal, Button, Form } from 'react-bootstrap'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import {
  BsCaretUpFill,
  BsCaretUp,
  BsCaretDown,
  BsCaretDownFill,
} from 'react-icons/bs'
import './OneBlockComponents.css'
import { AuthUserContext } from '../Session'
import {TalksContext, QuestionsContext} from './context'
import { EditQuestionModal} from '../AddTalk/AddQuestion'
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react'


const QuestionsTable = ({
  splinterGroup,
  blockId,
  talkOrder = [],
}) => {

  const questions = useContext(QuestionsContext)
  const talks = useContext(TalksContext)
  const questionsSortedByTime = Object.values(questions).filter(q => q.blockId === blockId).sort((a, b) => b.createdAt -a.createdAt)

  return (
    <div>
    {questionsSortedByTime.length ? (
      <div>

          <SubQuestionsSegment questions= {questionsSortedByTime} splinterGroup={splinterGroup} blockId={blockId} talkOrder={talkOrder} talks={talks}/>

  </div>):(<div></div>)}</div>)
  }

  const SubQuestionsSegment = ({talks, questions, splinterGroup, title,
    blockId,
    talkOrder = [], ...props}) => {
    const user = useContext(AuthUserContext)

    return (
      <Disclosure as="div" className="w-full sm:p-2">
        <DisclosureButton className="w-full pb-2 flex justify-center items-center">
          <div className='text-center text-2xl'>Questions</div>
          <ChevronDownIcon className="size-8 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180 shrink-0" />
        </DisclosureButton>
            <DisclosurePanel className="origin-top bg-slate-200 m-0 sm:p-2 rounded-b-lg">
              {questions.map(q=>(
              <div className='rounded border-1 border-black pl-1  bg-white my-2 first:mt-0 last:mb-0'>
                <div className='flex justify-between items-top'>
                  <div>
                  <div className='flex'>
                    <div className='font-bold'>{q.name}</div>
                    {(q.talkId !== 'general' && talks[q.talkId])&&
                    (<div className='flex whitespace-pre'>
                      <div>{" "+"to"+" "}</div>
                      <div className='italic'>{talks[q.talkId].name}</div></div>)}
                  </div>
                  <div className='text-xs leading-[.75em] pl-1 pb-1'><TimeAgo date={q.createdAt || q.createdAt.toMillis()} minPeriod={5} now={()=>new Date()}/></div>
                  </div>
                  {(user.isAdmin || user.email === q.email) && (<EditQuestionModal initialQ={q} splinterGroup={splinterGroup} blockId={blockId} talkOrder={talkOrder} className={'icon'}/>)}
                  </div>

                <div className=''>{q.question}</div>
              </div>))}
            </DisclosurePanel>
      </Disclosure>
    )

  }

  export default QuestionsTable

