import React from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from '../Navigation/index'
//import LandingPage from '../Landing'
import Footer from '../Footer'
import Header from '../Header'
import { withAuthorization } from '../Session'
import '../../index.css'

const App = () => {
  return <OnePage />
}

const OnePage = ({ ...props }) => {
  return (
    <div className='flex flex-col min-h-[100vh]'>
      <Header className='grow-0' />
      <Navigation className='grow-0' />
      <div className='grow'>
        <Outlet />
      </div>
      <Footer className='grow-0' />
    </div>
  )
}

export default withAuthorization(App)
