const firebaseConfig = {
  apiKey: 'AIzaSyDP24MXW-Phk4V57_6HorhFFLjVaV-CgDs',
  authDomain: 'gem-2024-95b6f.firebaseapp.com',
  databaseURL: 'https://gem-2024-95b6f-default-rtdb.firebaseio.com',
  projectId: 'gem-2024-95b6f',
  storageBucket: 'gem-2024-95b6f.appspot.com',
  messagingSenderId: '304534395828',
  appId: '1:304534395828:web:f07652776a5e05a0d6b0e1',
}
export default firebaseConfig
