import { useState, useContext } from "react"
import { FirebaseContext } from '../../server/Firebase'
import { POSTER_TIMES } from '../../constants/posterTimes.js'
import { Button, Form, FormCheck } from 'react-bootstrap'
import uploadingGif from '../../imgs/uploading.gif'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput.js'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel.js'

const UploadPosterSection = ({ poster, closeModal, hasToggle=false }) => {
  const [pdfFile, setPdfFile] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedTimes, setSelectedTimes] = useState(
    new Set(poster.times),
    new Set()
  )
  const [displayEmail, setDisplayEmail] = useState((poster.displayEmail || poster.displayEmail === '') ? poster.displayEmail : poster.email)
  const [isOpen, setIsOpen] = useState(!hasToggle)

  const firebase = useContext(FirebaseContext)
  return (
    <div>
      <div>
        {hasToggle? <Button onClick={()=>setIsOpen(!isOpen)}>
        <div className='pb-2'>{poster.postertitle}</div>
        </Button> : <div className='pb-2'>{poster.postertitle}</div>}
        {isOpen && <Form
          onSubmit={async (e) => {
            e.preventDefault()
            setIsUploading(true)
            await firebase.updatePoster({
              data: { ...poster, times: [...selectedTimes], displayEmail: displayEmail },
              pdfFile,
              videoFile,
            })
            setIsUploading(false)
            closeModal()
          }}
        >
          <Form.Group>
            <Form.Label>Your Poster (must be a PDF)</Form.Label>
            <Form.Control
              type='file'
              id='pdfFile'
              label='Your Poster (must be a PDF)'
              onChange={(e) => setPdfFile(e.target.files[0])}
              accept='.pdf'
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Your Video (mp4)</Form.Label>
            <Form.Control
              type='file'
              id='videoFile'
              label='Your Video (mp4)'
              onChange={(e) => setVideoFile(e.target.files[0])}
              accept='.mp4'
            />
            <Form.Text>
              Video is optional, but recommended. If you have only audio, please
              make it a video by playing it on top of an image of your poster.
            </Form.Text>
          </Form.Group>
          You can reupload your files at any time. The files you had previously
          uploaded will be overwritten.
          <br/>
          <br/>
          <Form.Group controlId='name'>
                  <Form.Label>Do you want your email visible so people can contact you? If so, type your email below.</Form.Label>
                  <Form.Control
                    defaultValue={displayEmail}
                    type='text'
                    onChange={(e) => setDisplayEmail(e.target.value)}
                  />
                </Form.Group>
          <br />
          <ChosePosterSession
            poster={poster}
            setSelectedTimes={setSelectedTimes}
            selectedTimes={selectedTimes}
          />
          <br />
          <Button variant='success' type='submit'>
            Submit
          </Button>
          <img
            src={uploadingGif}
            style={{ display: isUploading ? '' : 'none' }}
            alt='Spinning circle around the word uploading'
          ></img>
        </Form>}
      </div>
    </div>
  )
}

const ChosePosterSession = ({ poster, selectedTimes, setSelectedTimes }) => {
  const session = POSTER_TIMES[Object.keys(POSTER_TIMES).find(key => key===poster.focusGroup)] || 'none'
  if(session === 'none'){
    return
  }
  return (
    <div>
      <div className='p-2 pl-0'></div>
      <div className='pb-2'>
        It is mandatory that you present your poster at the main poster session
        on {(poster.day === 'Tue') ? 'Tuesday' : 'Thursday'}. There are other optional times that you can present
        your poster, to present with your focus group. Do you plan on presenting at the focus group session?
      </div>
      <FormCheck type='radio' key={poster.postertitle + session.id+'yes'}>
            <FormCheckInput
              type='radio'
              value={session.id+'yes'}
              name='presenting'
              id={session.id}
              onChange={(e) => {
                const copy = new Set(selectedTimes)
                if (e.currentTarget.checked) {
                  copy.add(session.id)
                  copy.delete('no')
                  setSelectedTimes(copy)
                }
              }}
              defaultChecked={selectedTimes.has(session.id)}
            ></FormCheckInput>
            <FormCheckLabel>
              {"Yes, "+ session.fg + ' - ' + session.day + ' ' + session.time}
            </FormCheckLabel>
          </FormCheck>
          <FormCheck type='radio' key={poster.postertitle + session.id+'no'}>
            <FormCheckInput
              type='radio'
              value={session.id+'no'}
              name='presenting'
              id={'no'}
              onChange={(e) => {
                const copy = new Set(selectedTimes)
                if (e.currentTarget.checked) {
                  copy.add('no')
                  copy.delete(session.id)
                  setSelectedTimes(copy)
                }
              }}
              defaultChecked={selectedTimes.has('no')}
            ></FormCheckInput>
            <FormCheckLabel>
              No
            </FormCheckLabel>
          </FormCheck>
    </div>
  )
}

export {UploadPosterSection}
