import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FirebaseContext } from '../../server/Firebase'
import { AuthUserContext, withAuthentication } from '../Session'
import { POSTER_CATEGORIES } from '../../constants/posterSched.js'
import { GROUPS } from '../../constants/splinterGroups.js'
import { Button, Modal } from 'react-bootstrap'
import { UploadPosterSection } from './UploadPosterSection.jsx'
import { POSTER_TIMES } from '../../constants/posterTimes.js'

const Poster = (section = 'all') => {
  let { posterId } = useParams()
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthUserContext)
  let poster = firebase.usePoster({ id: posterId })
  return (
    <div>
      {(poster.email === authUser.email || authUser.isAdmin) && (
        <div className='flex justify-center'>
          <UploadPosterButton poster={poster} />
        </div>
      )}
      <div
        className='topRow'
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div className='text' style={{ flexGrow: 5 }}>
          <h1 className='text-center py-3 text-3xl'>{poster.postertitle}</h1>
          <h5 className='text-center text-xl'>{poster.fullname}</h5>
          <h6 className='text-xl p-2 pb-0'>
            <strong>Presenting:</strong>{' '}
            {poster.day === 'Tue' ? 'Tuesday' : 'Thursday'} Poster Session
            {poster.times?.length > 0 && (
              <span>
                {poster.times
                  .filter((fg) => POSTER_TIMES[fg.toUpperCase()])
                  .map((fg) => {
                    fg = fg.toUpperCase()
                    return (
                      <span>
                        {' & ' +
                          POSTER_TIMES[fg].day +
                          ' ' +
                          POSTER_TIMES[fg].time}
                      </span>
                    )
                  })}
              </span>
            )}
          </h6>

          <h6 className='text-xl p-2 pb-0'>
            <strong>Research Area:</strong>{' '}
            {POSTER_CATEGORIES[poster.researchArea]}
          </h6>
          <h6 className='text-xl p-2 pb-0'>
            <strong>Focus Group:</strong>{' '}
            {GROUPS[poster.focusGroup]?.longName || poster.focusgrouplong}
          </h6>
          {poster.displayEmail && (
            <h7 className='p-2 text-xl'>
              Contact me at {poster.displayEmail}.
            </h7>
          )}
          {poster.abstract && (
            <div>
              <h6 className='text-xl p-2 pb-0 font-bold'>Abstract</h6>
              <p className='p-2'>{poster.abstract}</p>
            </div>
          )}
        </div>
      </div>
      {poster.pdfUrl && (
        <div className='flex justify-center'>
          <div className='sm:w-[90vw] sm:h-[50vw] w-[100vw] h-[56vw]'>
            <object
              data={poster.pdfUrl}
              width='100%'
              height='100%'
              aria-label='Poster'
            ></object>
          </div>
        </div>
      )}
      <br />
      {poster.videoUrl && (
        <div className='flex justify-center'>
          <div className='sm:w-[90vw]'>
            <video
              src={poster.videoUrl}
              height='100%'
              width='100%'
              controls
              type='video/mp4'
            />
          </div>
        </div>
      )}
      {/* <Comments parentId={posterId} posterId={posterId} /> */}
    </div>
  )
}

const UploadPosterButton = ({ poster = {} }) => {
  const [showModal, setShowModal] = useState(false)

  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      <div className='pt-2'>
        <Button onClick={() => handleToggle()} variant='success' type='button'>
          Upload your poster
        </Button>
      </div>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
        title='Upload'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Upload Poster Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div key={poster.id}>
            <UploadPosterSection
              poster={poster}
              startOpen={true}
              closeModal={handleToggle}
            />
            <br />
            <hr />
            <br />
          </div>
          <p>
            If you have any issues uploading your poster, please email me
            vgemhelp@gmail.com. Please include your name, poster title, and
            email you used to log into this website.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default withAuthentication(Poster)
