import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'

import { FirebaseContext } from '../../server/Firebase'

const SignOutButton = (props) => {
  const firebase = useContext(FirebaseContext)
  return (
    <Button variant='light' onClick={firebase.doSignOut} className='w-24'>
      Sign Out
    </Button>
  )
}

export default SignOutButton
