import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../../server/Firebase'
import { compose } from 'react-recompose'
import { AuthUserContext } from '../Session'
import OneBlockComponent from '../SplinterGroup/OneBlockComponents'
import { withAuthentication } from '../Session'
import { Modal, Button, Form } from 'react-bootstrap'
import { FaRegEdit } from 'react-icons/fa'
import { AiOutlineCloudUpload, AiOutlineCloudDownload } from 'react-icons/ai'
import {
  BsCaretUpFill,
  BsCaretUp,
  BsCaretDown,
  BsCaretDownFill,
} from 'react-icons/bs'
import './index.css'
import MeetingLink from '../MeetingLink'
import MiscVideoModal from '../MiscVideoModal'

const StudentRepCard = ({ studentRep = {} }) => {
  const user = useContext(AuthUserContext)
  const canEdit = user.email === studentRep.email || user.isAdmin
  return (
    <div className='card'>
      <div className='cardHeader'>
        {canEdit && (
          <div className='editContainer'>
            <EditButton studentRep={studentRep} isEdit={true} user={user} />
          </div>
        )}
        <div className='cardName'>
          {studentRep.picUrl && (
            <img
              src={studentRep.picUrl}
              className='profileImage'
              alt={`Profile of ${studentRep.name}`}
            ></img>
          )}
          {studentRep.name}
        </div>
      </div>
      <div className='bio'>{studentRep.bio}</div>
      {studentRep.videoUrl && (
        <video src={studentRep.videoUrl} controls='controls'></video>
      )}
    </div>
  )
}

let StudentRepPage = ({ ...props }) => {
  const firebase = useContext(FirebaseContext)
  const studentReps = firebase.useStudentRepTalks()
  const user = useContext(AuthUserContext)
  const meeting = firebase.useMiscMeeting({ session: 'student-rep' })

  return (
    <div>
      <h3 className='text-center py-3 text-5xl'>
        Student Rep candidate presentations
      </h3>
      <h5 className='text-center text-3xl'>
        {'Wednesday, 12:00-1:30pm MT time'}
      </h5>
      {meeting?.link && (
        <p className='flex justify-center'>
          Join us remotely!{' '}
          {<MeetingLink link={meeting.link} info={meeting.linkInfo} />}
        </p>
      )}
      <EditButton />

      {user?.isAdmin && (
        <>
          <MiscVideoModal
            session='student-rep'
            initialVideoLink={meeting?.link}
            initialVideoInfo={meeting?.linkInfo}
          />
          <br />
          <br />
        </>
      )}

      <div className='cardContainer'>
        {studentReps.map((studentRep, i) => (
          <StudentRepCard studentRep={studentRep} key={i} />
        ))}
      </div>
    </div>
  )
}

const EditButton = ({ studentRep = {}, isEdit = false }) => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  let displayName = studentRep.name || user.name || ''
  const [showModal, setShowModal] = useState(false)
  const [name, setName] = useState(displayName)
  const [bio, setBio] = useState(studentRep.bio ?? '')
  const [video, setVideo] = useState(null)
  const [pic, setPic] = useState(null)
  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      {isEdit ? (
        <FaRegEdit size='30' onClick={() => handleToggle()} title='Edit' />
      ) : (
        <Button
          type='button'
          variant='success'
          onClick={() => handleToggle()}
          style={{ margin: '10px' }}
        >
          Add your candidacy info!
        </Button>
      )}
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Your Candidacy Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              firebase.postStudentRepTalk({
                data: { ...studentRep, name, bio, email: user.email },
                pic,
                video,
              })
              e.preventDefault()
              setShowModal(false)
            }}
          >
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Label>Bio</Form.Label>
              <Form.Control
                defaultValue={bio}
                type='textarea'
                onChange={(e) => setBio(e.target.value)}
              />{' '}
              <br />
              <Form.Label>
                Optional picture of your face! (or your pet!)
              </Form.Label>
              <Form.Control
                type='file'
                id='pic'
                label='Optional picture of your face! (or your pet!)'
                accept='image/*'
                onChange={(e) => setPic(e.target.files[0])}
              />
              <br />
              <Form.Label>Your Optional Video (mp4)</Form.Label>
              <Form.Control
                type='file'
                id='video'
                label='Your Optional Video (mp4)'
                accept='.mp4'
                onChange={(e) => setVideo(e.target.files[0])}
              />
            </Form.Group>

            <Form.Text className='text-muted'>
              You can reupload your files at any time. The files you had
              previously uploaded will be overwritten.
            </Form.Text>
            <br />
            <Button variant='primary' type='submit'>
              Submit
            </Button>
          </Form>
          <br />
          <br />
          {studentRep.id && (
            <div>
              <h3 className='left'>Do you want to delete this?</h3>
              <p>This deletes the talk permanently from the database.</p>
              <Button
                variant='danger'
                onClick={() => {
                  firebase.deleteStudentRepTalk({
                    data: studentRep,
                  })
                  handleToggle()
                }}
              >
                I want to delete this talk
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const condition = (authUser) => !!authUser
export default withAuthentication(StudentRepPage)
