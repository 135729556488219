import React, { useContext, useEffect, useState } from 'react'
import AuthUserContext from './context'
import { FirebaseContext } from '../../server/Firebase'

const withAuthorization = (Component) => {
  const WithAuthorization = (props) => {
    const [authUser, setAuthUser] = useState(
      JSON.parse(localStorage.getItem('authUser'))
    )
    const firebase = useContext(FirebaseContext)

    useEffect(() => {
      firebase.onAuthUserListener(
        (user) => {
          localStorage.setItem('authUser', JSON.stringify(user))
          setAuthUser(user)
        },
        () => {
          localStorage.removeItem('authUser')
          setAuthUser(null)
        }
      )
    }, [firebase])
    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} user={authUser} />
      </AuthUserContext.Provider>
    )
  }
  return WithAuthorization
}

export default withAuthorization
