const CodeOfConduct = () => (
  <div className='sm:p-2'>
    <header>
      <h1 className='text-center py-3 text-5xl'>Code of Conduct</h1>
    </header>
    <div id='content' className='post-single-content box mark-links'>
      <p className='pb-3'>
        The Geospace Environment Modeling (GEM) program is committed to
        providing a safe, free from harassment, productive and welcoming
        environment for all. This includes respectful treatment of everyone
        regardless of sex, gender identity or expression, sexual orientation,
        disability, physical appearance, age, body size, race, religion,
        national origin, ethnicity, level of experience, political affiliation,
        as well as any other characteristic protected under state or federal
        law. This policy applies to all attendees at all GEM activities
        including scientists, students, guests, staff, and contractors
        participating in scientific sessions, auxiliary meetings, and social
        events associated with any GEM gathering or other activity.
      </p>

      <p className='pb-3'>
        Sections A and B, contain information where to find policy documentation
        including the prohibited and expected behaviors, definition of
        harassment, the procedure for reporting harassment, and the procedures
        and responsibilities of the GEM Meeting Organizer and Steering Committee
        in addressing harassment (including external harassment cases).
      </p>

      <p className='pb-3'>
        If you are the subject of unacceptable behavior or assault or may have
        witnessed such behavior that violates professional and respectful
        participant conduct, please immediately report the incident to the
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.unh.edu/diversity-inclusion/civil-rights-equity-office'
        >
          {' '}
          UNH Civil Rights and Equity Office (CREO).
        </a>{' '}
        Please report the incident using the
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.unh.edu/diversity-inclusion/civil-rights-equity-office/incident-report-form'
        >
          {' '}
          Incident Report Form
        </a>{' '}
        or via email to UNH.CivilRights@unh.edu or phone to (603)&nbsp;
        862-2930. You have the option to submit an anonymous report but please
        be aware that by doing so you may limit UNH’s ability to respond
        fully.&nbsp;
      </p>

      <p className='pb-3'>
        Whether or not an incident is reported to the grant holder’s
        institution, you may find a trusted GEM Steering Committee member to
        discuss an incident when immediate action is needed. That Steering
        Committee member will share anonymous details with the GEM Meeting
        Organizer. Disclosures of this nature will be kept confidential and will
        not be reported to the Steering Committee, family members or others
        involved in the workshop.
      </p>

      <p className='pb-3'>
        If there is a harassment complaint or investigation involving people
        covered by this policy that occurred in a setting external to GEM, such
        as at a home institution or another meeting, the GEM Meeting Organizer
        may take actions to preserve the security of the meeting and its
        participants. Whether previous or ongoing, attendance can be restricted
        or barred as deemed appropriate. The Meeting Organizer is responsible
        for the actions being carried out; if the Meeting Organizer is
        conflicted, the Chair is responsible for the actions being carried
        out.&nbsp;
      </p>

      <p className='pb-3'>
        All participants are required to abide by this Code of Conduct. If there
        has been a violation of this Code, consequences may include:
      </p>

      <ul className='list-disc pl-5 pb-3'>
        <li className=''>
          Requiring the accused Participant to leave the GEM workshop at the
          Participant’s own cost, without refund;
        </li>
        <li className=''>
          Prohibiting the accused Participant from attending future GEM
          Events;&nbsp;
        </li>
        <li className=''>
          UNH may also contact a participant’s institution to report the
          conduct;&nbsp; and
        </li>
        <li className=''>
          Any other actions deemed necessary and appropriate.
        </li>
      </ul>

      <h2 className='text-2xl'>
        <strong>A. </strong>
        <strong>Prohibited and Expected Behaviors</strong>
      </h2>

      <p NameName=''>
        GEM expects all Participants to comply with the letter and spirit of
        this Code. GEM steering committee members reserve the right to enforce
        this Code against any Participant found to have violated GEM’s
        commitment to a professional, respectful, inclusive environment even if
        a specific behavior is not listed below. This policy applies to all
        attendees at the GEM workshop, including scientists, students, guests,
        staff, and contractors participating in scientific sessions, auxiliary
        meetings and social events associated with any GEM gathering or other
        activity.
      </p>

      <p className='pb-3'>
        Anyone who doesn’t meet expected collaborative, respectful behavior can
        immediately be removed from the current online or physical workshop and
        may be banned from future GEM workshops.
      </p>

      <p className='pb-3'>
        1.{' '}
        <strong>
          Any sexual harassment and/or misconduct are prohibited.{' '}
        </strong>
        Section B provides sexual harassment and misconduct definitions. Also,
        definitions of sexual harassment, assault, violence and gender-based
        violence can be found on the NSF website at{' '}
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://new.nsf.gov/stopping-harassment#prohibited-behaviors-and-definitions-e71'
        >
          https://new.nsf.gov/stopping-harassment#prohibited-behaviors-and-definitions-e71
        </a>
      </p>

      <p className='pb-3'>
        2. <strong>Any harassment is prohibited.</strong> Harassment is defined
        as any unwelcome verbal or physical conduct based on race, color,
        religion, sex, gender, disability, age, national origin, sexual
        orientation that is objectively hostile as to alter the ability of an
        individual to engage in GEM activities by creating a hostile environment
        (see Section B).
      </p>

      <p className='pb-3'>
        3. <strong>Retaliation is prohibited</strong>. “Retaliation” is defined
        as taking an adverse action against an individual, or subjecting an
        individual to conduct, with the purpose of unreasonably creating an
        environment that is hostile because of something that individual did to
        further this policy. This includes, but is not limited to, a person
        reporting an incident, any people who served as witnesses, and people
        supporting or opposing a complaint. Retaliation also includes
        interfering with the reporting of, or the investigation of, prohibited
        behavior. Examples include tampering with or destroying relevant
        evidence and intimidating, threatening or attempting to influence, in
        any way, the testimony or information of a complainant or witness.
        Retaliation is a serious violation of this policy and, like harassment
        or discrimination itself, is subject to disciplinary action as described
        in the previous section.
      </p>

      <p className='pb-3'>
        4. <strong>Concerns should be reported immediately</strong> to the
        appropriate person, channels, or entity, as described in Section B of
        this document, so that the GEM steering committee, legal office or Title
        IX office can act quickly to address and resolve issues (see section B
        below for details on how to report concerns).&nbsp;
      </p>

      <p className='pb-3'>
        5. <strong>Confidentiality</strong> of the identities of any individuals
        involved in a conduct concern should be respected while it is being
        reviewed and addressed.
      </p>

      <p className='pb-3'>
        6. Participants should <strong>treat everyone with respect</strong> and
        do not engage in conduct or make comments that are biased, demeaning,
        intimidating, coercive, or harassing/hostile.
      </p>

      <p className='pb-3'>
        7. Participants should not{' '}
        <strong>touch or make physical contact </strong>with anyone unless they
        know such contact is welcome.
      </p>

      <p className='pb-3'>
        8. Participants should maintain a <strong>professional behavior</strong>{' '}
        at the workshop venue.
      </p>

      <p className='pb-3'>
        9. Participants should{' '}
        <strong>act professionally and responsibly</strong> if they choose to
        drink when alcohol is available, or if they use other legal intoxicants.
      </p>

      <p className='pb-3'>
        10. Participants should not engage in violence or abuse of any attendee,
        speaker, volunteer, exhibitor, staff member, service provider, or other
        guest, physically, verbally or through any other form.
      </p>

      <p className='pb-3'>
        11. Participants should respect the rules and policies of the workshop
        venue, hotels, online platform, or any other venue associated with the
        workshop.&nbsp;
      </p>

      <p className='pb-3'>
        12. Scientific participants, including students, should
        <strong> display scientific integrity</strong> in disseminating their
        research.
      </p>

      <p className='pb-3'>
        13. Scientific participants should<strong> recognize co-authors</strong>{' '}
        that have made <em>bona fide</em> contributions to their work provided
        they agree to be recognized. Scientific participants should not
        plagiarize the work of others.
      </p>

      <p className='pb-3'>
        14. During the GEM workshop, presentation materials will be available
        online for registered attendees to view. GEM members will respect the
        intellectual property rights of others and follow best practices.
      </p>

      <h2 className='text-3xl'>
        <strong>B. </strong>
        <strong>Participant Notice</strong>
      </h2>

      <p className='pb-3'>
        This workshop is partially supported by the National Science Foundation
        under an award to the University of New Hampshire (UNH). We are required
        by the NSF Proposal &amp; Award Policies &amp; Procedures Guide (Chapter
        II.E.7), effective February 25, 2019, to provide all event participants
        with information on the University’s policy on sexual and other forms of
        harassment or sexual assault as well as directions on how to report any
        violations of this policy. For purposes of this requirement, “other
        forms of harassment” is defined as “non-gender or non-sex-based
        harassment of individuals protected under federal civil rights laws, as
        set forth in organizational policies or codes of conduct, statutes,
        regulations, or executive orders.”&nbsp;
      </p>

      <p className='pb-3'>
        The University of New Hampshire (UNH) seeks excellence through diversity
        among its administrators, faculty, staff and students. We are committed
        to enhancing and sustaining an educational community that is inclusive
        and equitable, and cherish these values as being inextricably linked to
        our core mission. We are a public institution with a long-standing
        commitment to equal employment and educational opportunity for all
        qualified persons. We do not discriminate on the basis of race, color,
        religion, sex, age, national origin, sexual orientation, gender identity
        or expression, disability, veteran status, marital status, genetic
        information, or pregnancy. This applies to admission to, access to,
        treatment within, or employment in UNH programs or activities.
      </p>

      <p className='pb-3'>
        It is National Science Foundation (NSF) policy to foster harassment-free
        environments wherever science is conducted, including at NSF-sponsored
        conferences, workshops, and symposia. This conference/workshop/symposium
        is supported all or in part by the NSF under Award No. 1800948.
      </p>

      <p className='pb-3'>
        In accordance with NSF Proposal &amp; Award Policies &amp; Procedures
        Guide (Chapter II.E.7), effective February 25, 2019, UNH is providing
        all event participants with information on the University’s policies
        regarding sexual harassment, other forms of harassment, or sexual
        assault as well as directions on how to report any violations of these
        policies. The University has comprehensive policies prohibiting
        discrimination and harassment, including sexual violence and sexual
        harassment, which include complete descriptions of the prohibited
        conduct:
      </p>
      <ul className='list-disc'>
        <li>
          <p className='pb-3'>
            <a
              className='text-blue-500 hover:text-red-500'
              href='https://catalog.unh.edu/srrr/code-conduct-process/sexual-misconduct/'
            >
              Sexual Misconduct and Sexual Exploitation
            </a>{' '}
            (the University’s standards of conduct, investigation process and
            resolutions of claims of prohibited conduct by students)
          </p>
        </li>
        <li>
          <p className='pb-3'>
            <a
              className='text-blue-500 hover:text-red-500'
              href='https://www.usnh.edu/policy/unh/v-personnel-policies/b-affirmative-action-and-equity'
            >
              Discrimination and Discriminatory Harassment Policy
            </a>{' '}
            (the University’s standard of conduct, and process for investigating
            and adjudicating complaints of discrimination or discriminatory
            harassment by any employee: faculty, staff, or administrator)
          </p>
        </li>
      </ul>
      <p className='pb-3'>
        Conference attendees wishing to report sexual harassment, other forms of
        harassment, or sexual assault should contact the
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.unh.edu/diversity-inclusion/civil-rights-equity-office'
        >
          {' '}
          UNH Civil Rights and Equity Office (CREO).
        </a>{' '}
        Please report the incident using the
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.unh.edu/diversity-inclusion/civil-rights-equity-office/incident-report-form'
        >
          {' '}
          Incident Report Form
        </a>{' '}
        or via email to UNH.CivilRights@unh.edu or phone to (603)&nbsp;
        862-2930. You have the option to submit an anonymous report but please
        be aware that by doing so you may limit UNH’s ability to respond fully.
      </p>

      <p className='pb-3'>
        UNH will respond promptly and effectively to reports of prohibited
        conduct and take appropriate action to prevent, correct, and when
        necessary and possible, to discipline behavior that violates these
        policies. UNH will make any required notifications to the
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.nsf.gov/od/oecr/'
        >
          {' '}
          NSF Office of Equity and Civil Rights,
        </a>{' '}
        and other external agencies as appropriate. UNH may also contact a
        participant’s institution to report the conduct.
      </p>

      <p className='pb-3'>
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.unh.edu/diversity-inclusion/sexual-misconduct/resources/privileged-confidential-support-services'
        >
          Privileged confidential support services
        </a>{' '}
        are available for students, faculty and staff seeking medical or
        counseling support or general advice about their options, but do not
        want to make a report of the incident. Such support services are
        available on campus or off campus.
      </p>

      <p className='pb-3'>
        Dial 911 for emergency situations and/or immediate safety concerns.
        Conference attendees wishing to report a matter to law enforcement can
        contact
        <a
          className='text-blue-500 hover:text-red-500'
          href='https://www.unh.edu/upd/'
        >
          {' '}
          UNH’s police department (UNHPD)
        </a>{' '}
        for on-campus incidents or the local police department where the crime
        occurred. Reports to UNHPD can be made in person at 18 Waterworks Road,
        Durham, NH, or by phone through the non-emergency line at (603)
        862-1427.
      </p>
    </div>
  </div>
)

export { CodeOfConduct }
