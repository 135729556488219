const PLENARY = {
  monday: {
    hosts: ['Christine Gabrielse'],
    name: 'Monday Plenary Session',
    date: 'Monday, June 20',
    time: '8:00am-10:00am',
    done: false,
    presentations: ['mon1', 'mon2', 'mon3'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=1-plenary1-monday',
    room: 'AB',
    id: 'mon',
  },
  tuesday: {
    hosts: ['Christine Gabrielse'],
    name: 'Tuesday Plenary Session',
    date: 'Tuesday, June 21',
    time: '8:00am-10:00am',
    done: false,
    presentations: ['tue2', 'tue3', 'tue4', 'tue5', 'tue6'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=1-plenary2-tuesday',
    room: 'AB',
    id: 'tue',
  },
  wednesday: {
    hosts: ['Christine Gabrielse'],
    name: 'Wednesday Plenary Session',
    date: 'Wednesday, June 22',
    time: '8:00am-10:00am',
    done: false,
    presentations: ['wed2', 'wed3'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=1-plenary3-wednesday',
    room: 'AB',
    id: 'wed',
  },
  thursday: {
    hosts: ['Christine Gabrielse'],
    name: 'Thursday Plenary Session',
    date: 'Thursday, June 23',
    time: '8:00am-10:00am',
    done: false,
    presentations: ['thu2', 'thu3'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=1-plenary4-thursday',
    room: 'AB',
    id: 'thu',
  },
  friday: {
    hosts: ['Christine Gabrielse'],
    name: 'Friday Plenary Session',
    date: 'Friday, June 24',
    time: '8:00am-10:00am',
    done: false,
    presentations: ['fri2', 'fri3'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=1-plenary5-friday',
    room: 'AB',
    id: 'fri',
  },
}
const PLENARY_PRESENTATIONS = {
  mon1: {
    title: 'Opening Remarks',
    host: 'Christine Gabrielse',
  },
  mon2: {
    title: 'MPS Tutorial',
    // subtitle:
    //   'From Speiser Orbits to Substorms: What is the Path to a Multiscale GGCM?',
    host: 'Harry Arnold',
    moderators: ['Shan-Chang Lin', 'Espen Fredrick'],
  },
  mon3: {
    title: 'SBCCI - DEI Tutorial',
    // subtitle: 'Hidden Gems of Inner Magnetospheric Research',
    host: 'Mike Liemohn',
    moderators: ['Shan-Chang Lin', 'Espen Fredrick'],
  },
  tue1: {
    title: 'Chair',
    host: 'Christine Gabrielse',
  },

  tue2: {
    title: 'NSF',
    // subtitle: 'GEM Update from the National Science Foundation',
    host: 'Chia-Lin Huang',
    order: 4,
  },
  tue3: {
    title: 'NASA',
    // subtitle: 'Heliophysics Division Update',
    host: 'Joseph Westlake',
    order: 6,
  },
  tue4: {
    title: 'NOAA',
    // subtitle: 'NOAA Liason Report: Hightlights of Activities',
    host: 'Howard Singer',
    order: 7,
  },
  tue5: {
    title: 'AFRL',
    // subtitle: 'Highlights from AFRL work in Geospace',
    host: 'Shawn Young',
    order: 8,
  },
  tue6: {
    title: 'Heliophysics Coalition Speaker',
    host: 'Ian Cohen',
  },
  wed1: {
    title: 'Chair',
    host: 'Christine Gabrielse',
  },
  wed2: {
    title: 'MIC Tutorial',
    // subtitle: 'Impact of Thermosphere Dynamics on MI Coupling',
    host: 'Hyomin Kim',
    moderators: ['Jodie McLennan', 'Xingzhi Lyu'],
  },
  wed3: {
    title: 'GSM Tutorial',
    host: 'Weichao Tu',
    moderators: ['Jodie McLennan', 'Xingzhi Lyu'],

    // subtitle:
    //   'Outreach Strategies for the Daniel K. Inouye Solar Telescope',
    // host: 'Claire Raftery',
    // moderators: ['Espen Fredrick', 'Grant Berland'],
  },
  thu1: {
    title: 'Chair',
    host: 'Christine Gabrielse',
  },

  thu2: {
    title: 'ML Tutorial',
    // subtitle: 'Solar-Wind/Magnetosphere Coupling: The Basics?',
    host: 'Hyunju Connor',
    moderators: ['Jayasri Joseph', 'Zhi Gu Li'],
  },
  thu3: {
    title: 'IMAG Tutorial',
    // subtitle:
    //   'Influence of the Kelvin-Helmholtz Instability on the Plasma Entry, Transport and Energization at the Magnetospheric Boundary Layers',
    host: 'Xinlin Li',
    moderators: ['Jayasri Joseph', 'Zhi Gu Li'],
  },
  fri1: {
    title: 'Chair',
    host: 'Christine Gabrielse',
  },

  fri2: {
    title: 'SWMI Tutorial',
    // subtitle:
    //   'Heliophysics missions and instrumentation: Past progress towards future implementations',
    host: 'Yuxi Chen',
    moderators: ['Dinesh Kumar', 'Veypatty Radhakrishnan'],
  },
  fri3: {
    title: 'Student Tutorials',
    // subtitle:
    //   'Here, There, or Everywhere? Validation and Evaluation in Solar and Heliospheric (SHINE) Science',
    host: 'Frances Staples',
    moderators: ['Hsinju Chen', 'Austin Smith'],
  },
}

export { PLENARY, PLENARY_PRESENTATIONS }
