import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FirebaseContext } from '../../server/Firebase'
import uploadingGif from '../../imgs/uploading.gif'
import { ToggleButton } from 'react-bootstrap'

const AddVideoModal = ({
  session = '',
  initialVideoLink = '',
  initialVideoInfo = '',
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [videoLink, setVideoLink] = useState(initialVideoLink)
  const [videoInfo, setVideoInfo] = useState(initialVideoInfo)
  const [savedLink, setSavedLink] = useState('')
  const [savedInfo, setSavedInfo] = useState('')
  const [deleteSelected, setDeleteSelected] = useState(false)
  const [error, setError] = useState('')
  const firebase = useContext(FirebaseContext)

  const toggleModal = (bool = null) => {
    setVideoLink(initialVideoLink)
    setVideoInfo(initialVideoInfo)
    if (bool != null) {
      setShowModal(!showModal)
    } else {
      setShowModal(bool)
    }
  }

  const onRemoveClicked = (e) => {
    const checked = e.currentTarget.checked
    if (checked) {
      setSavedLink(videoLink)
      setSavedInfo(videoInfo)
      setVideoLink('')
      setVideoInfo('')
    } else {
      setVideoInfo(savedInfo)
      setVideoLink(savedLink)
    }
    setDeleteSelected(checked)
  }

  return (
    <>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={toggleModal}
        key={session + 'initialButton'}
      >
        {initialVideoLink ? 'Change Meeting' : 'Add Meeting'}
      </Button>
      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        key={session + 'modal'}
        size='lg'
      >
        <Modal.Header closeButton key={session + 'header'}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add a Meeting Link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body key={session + 'body'}>
          {error && <span style={{ color: 'red' }}>{error.message}</span>}
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              setIsUploading(true)
              try {
                await firebase.addMiscMeeting({
                  link: videoLink,
                  info: videoInfo,
                  session,
                })
              } catch (err) {
                setIsUploading(false)
                setError(err)
                console.error(err)
                return
              }
              setIsUploading(false)
              setShowModal(false)
            }}
          >
            <Form.Group controlId='link' key={session + 'link'}>
              <Form.Label>Video Link</Form.Label>
              <Form.Control
                type='text'
                placeholder='Video Link'
                onChange={(e) => setVideoLink(e.target.value)}
                value={videoLink}
                disabled={deleteSelected}
              />
            </Form.Group>
            <br />
            <Form.Group controlId='info' key={session + 'info'}>
              <Form.Label>Video Info</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Any additional information regarding the meeting link, including (for example) how to call in.'
                value={videoInfo}
                disabled={deleteSelected}
                onChange={(e) => setVideoInfo(e.target.value)}
              />
            </Form.Group>
            <br />
            <ToggleButton
              className='mb-2'
              id='toggle-check'
              type='checkbox'
              variant='outline-danger'
              checked={deleteSelected}
              value='1'
              onChange={onRemoveClicked}
            >
              {' '}
              Remove All Meeting Information
            </ToggleButton>

            <br />
            <br />
            <Button variant='primary' type='submit' key={session + 'submit'}>
              Submit
            </Button>
            {isUploading && (
              <img
                src={uploadingGif}
                alt='Spinning circle around the word uploading'
              ></img>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddVideoModal
