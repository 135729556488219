const POSTER_TIMES = {
  SCIMM: {
    id: 'scimm',
    fg: 'SCIMM',
    day: 'Monday',
    session: 'Session 2',
    time: '1:30-3:00',
  },
  MPEC: {
    id: 'mpec',
    fg: 'MPEC',
    day: 'Monday',
    session: 'Session 3',
    time: '3:30-5:00',
  },
  BSH: {
    id: 'bsh',
    fg: 'BSH',
    day: 'Tuesday',
    session: 'Session 1',
    time: '10:30-12:00',
  },
  KiTS: {
    id: 'kits',
    fg: 'KiTS',
    day: 'Tuesday',
    session: 'Session 2',
    time: '12:30-3:00',
  },
  MESO: {
    id: 'meso',
    fg: 'MESO',
    day: 'Tuesday',
    session: 'Session 3',
    time: '3:30-5:00',
  },
  RB: {
    id: 'rb',
    fg: 'RB',
    day: 'Wednesday',
    session: 'Session 1',
    time: '10:30-12:00',
  },
  COMP: {
    id: 'comp',
    fg: 'COMP',
    day: 'Wednesday',
    session: 'Session 2',
    time: '12:30-3:00',
  },
  CP: {
    id: 'cp',
    fg: 'CP',
    day: 'Thursday',
    session: 'Session 1',
    time: '10:30-12:00',
  },
  GIC: {
    id: 'gic',
    fg: 'GIC',
    day: 'Thursday',
    session: 'Session 2',
    time: '12:30-3:00',
  },
  RX: {
    id: 'rx',
    fg: 'RX',
    day: 'Thursday',
    session: 'Session 3',
    time: '3:30-5:00',
  },
}

export { POSTER_TIMES }
