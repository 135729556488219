import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { compose } from 'react-recompose'

import AuthUserContext from './context'
import { FirebaseContext } from '../../server/Firebase'
import * as ROUTES from '../../constants/routes'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const authUser = useContext(AuthUserContext)

    return !!authUser ? (
      <Component {...props} />
    ) : (
      <Navigate to={'/' + ROUTES.SIGN_IN} />
    )
  }
  return WithAuthentication
}

export default withAuthentication
