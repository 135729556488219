import React from 'react'
import unhLogo from '../../imgs/LeftSingleBlueWeb_RGB.png'
import SlackLink from '../SlackLink'
import YoutubeLink from './Youtube'
import wikiImg from '../../imgs/wiki.png'
import gemLogo from '../../imgs/gemLogo.png'

const WikiLink = () => (
  <a
    href={'https://gem.epss.ucla.edu'}
    target='_blank'
    rel='noopener noreferrer'
    title='Link to GEM Wiki'
  >
    <img
      src={wikiImg}
      alt='Link to GEM Wiki'
      height='30'
      width='30'
      style={{ margin: '5px' }}
    />
  </a>
)

const GemWorkshopLink = () => (
  <a
    href={'https://gemworkshop.org'}
    target='_blank'
    rel='noopener noreferrer'
    title='Link to GEM Workshop Site'
  >
    <img
      src={gemLogo}
      alt='Link to GEM Workshop Site'
      height='30'
      width='30'
      style={{ margin: '5px' }}
    />
  </a>
)
const Footer = () => (
  <div className='bg-[#1b1b1b] mt-1'>
    <p></p>
    <center>
      <img
        src={unhLogo}
        className='w-[300px] p-2'
        alt='University of NH logo'
      />
    </center>
    <p className='text-center text-slate-300'>
      If you are experiencing any technical difficulties or have a question,
      please contact the Workshop Organizers at info@gemworkshop.org
    </p>
    <p></p>
    <div className='flex justify-between items-center'>
      <p className='text-slate-300 px-2'>
        Site by{' '}
        <a href='https://comp.coop' className='text-[#e38e31]'>
          comp.coop
        </a>
      </p>
      <div className='flex'>
        <GemWorkshopLink />
        <YoutubeLink />
        <WikiLink />
        <SlackLink />
      </div>
    </div>
  </div>
)

export default Footer
