const ROOMS = {
  S346: { title: 'Salon III, IV, VI', displayShort: 'S346' },
  S3: { title: 'Salon III', displayShort: 'S3' },
  S46: { title: 'Salon IV, VI', displayShort: 'S46' },
  GG: { title: 'Gold & Green', displayShort: 'G&G' },
  A: { title: 'Atrium', displayShort: 'A' },
  RAMS: { title: 'RAMS', displayShort: 'RAMS' },
  S1: { title: 'Salon I, II, V', displayShort: 'S125' },
  L: { title: 'Legends', displayShort: 'L' },
  CPB: { title: `CSU President's Boardroom`, displayShort: 'CPB' },
}

export { ROOMS }
