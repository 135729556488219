import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { HiVideoCamera } from 'react-icons/hi'
import './index.css'

const MeetingLink = ({ link = '', info }) => {
  const [show, setShow] = useState(false)
  const toggleShow = (bool) => setShow(bool)
  return (
    <>
      <HiVideoCamera
        size='30'
        onClick={() => toggleShow(true)}
        className='icon'
      />
      <Modal
        show={show}
        size='lg'
        centered
        backdrop={true}
        onHide={() => toggleShow(false)}
      >
        <Modal.Body>
          <h3>
            <a
              href={link.startsWith('http') ? link : 'https://' + link}
              target='_blank'
              rel='noopener noreferrer'
              title='Open Meeting'
              className='text-blue-500 hover:text-red-500'
            >
              Go to Video Meeting
            </a>
          </h3>
          {info && (
            <span className='text'>
              <br />
              <strong>Video Info: </strong>
              <br />
              {info}
            </span>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MeetingLink
