import React, { useContext, useEffect, useState } from 'react'
import { BLOCKS } from '../../constants/blocks'
import { GROUPS } from '../../constants/splinterGroups'
import OneBlockComponent from './OneBlockComponents'
import { TalksContext, QuestionsContext } from './context'
import { FirebaseContext } from '../../server/Firebase'
import { useLocation, useNavigate } from 'react-router-dom'

const AllBlocksComponent = ({ splinterGroup }) => {
  const [selectedSession, setSelectedSession] = useState('')
  const firebase = useContext(FirebaseContext)
  const navigate = useNavigate()
  const { pathname, hash, key } = useLocation()

  const isSplinterGroup = !!GROUPS[splinterGroup]
  let blockIds = isSplinterGroup ? [...GROUPS[splinterGroup].blocks] : []
  let blocks = []

  for (const id of blockIds) {
    if (id === 'unscheduled') continue
    const day = id.split('-')[0]
    const block = Object.keys(BLOCKS[day])
      .map((key) => BLOCKS[day][key])
      .find((section) => section.id === id)
    blocks.push(block)
  }
  const unscheduledBlock = {
    name: 'Unscheduled Talks',
    id: 'unscheduled',
    groups: [splinterGroup],
    rooms: [],
    time: '',
  }
  blocks.push(unscheduledBlock)

  useEffect(() => {
    if (hash !== '') {
      setSelectedSession(hash.slice(1))
    }
    return () => {}
  }, [pathname, hash, key, navigate]) // do this on route change

  const SessionTabs = () => {
    return (
      <div className='flex flex-wrap justify-center gap-2 '>
        {blocks.map((block) => (
          <div
            className={`p-2 rounded text-center cursor-pointer ${
              selectedSession !== block.id
                ? 'bg-[#8c8cd2]'
                : 'bg-[#2d5e99] text-white border-1 border-black'
            }`}
            onClick={() => navigate('#' + block.id)}
            key={'tab' + block.id}
          >
            {block.id.charAt(0).toUpperCase() +
              block.id.slice(1).replace('-', ' ')}
          </div>
        ))}
        <div
          className={`py-2 px-4 rounded text-center cursor-pointer ${
            selectedSession !== 'all'
              ? 'bg-[#8c8cd2]'
              : 'bg-[#2d5e99] text-white border-1 border-black'
          }`}
          onClick={() => navigate('#all')}
          key={'all'}
        >
          All
        </div>
      </div>
    )
  }

  const partners = {}
  const room = {}
  for (const block of blocks) {
    const isJoint = splinterGroup
      ? !block.groups.includes(splinterGroup)
      : false
    const isUnscheduled = block.id === 'unscheduled'
    const roomInd = !isJoint
      ? block.groups.indexOf(splinterGroup)
      : block.groups.findIndex(
          (elem) => (typeof elem !== 'string') & elem.includes(splinterGroup)
        )
    partners[block.id] =
      isJoint && !isUnscheduled
        ? block.groups[roomInd].filter((elem) => elem !== splinterGroup)
        : []
    room[block.id] = !isUnscheduled ? block.rooms[roomInd] : ''
  }

  const sessionIds = blocks.map((block) => block.id)

  return (
    <div>
      <TalksContext.Provider value={firebase.useTalks({ splinterGroup })}>
        <QuestionsContext.Provider
          value={firebase.useQuestions({ splinterGroup })}
        >
          <SessionTabs />
          {sessionIds.includes(selectedSession) ? (
            <OneBlockComponent
              block={blocks.find((block) => block.id === selectedSession)}
              splinterGroup={splinterGroup}
              blocks={blocks}
              partners={partners}
              room={room[selectedSession]}
            />
          ) : (
            <div>
              {blocks.map((block) => (
                <div key={splinterGroup + block.name}>
                  {block.id !== 'unscheduled' ? (
                    <OneBlockComponent
                      block={block}
                      splinterGroup={splinterGroup}
                      blocks={blocks}
                      partners={partners}
                      room={room[block.id]}
                    />
                  ) : (
                    <OneBlockComponent
                      block={unscheduledBlock}
                      key={'unscheduled'}
                      splinterGroup={splinterGroup}
                      blocks={blocks}
                      room={''}
                      partners={partners}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </QuestionsContext.Provider>
      </TalksContext.Provider>
    </div>
  )
}

export default AllBlocksComponent
