import React, { useState, useEffect, useContext } from 'react'
import { Button, Form, FormCheck, Modal, Table } from 'react-bootstrap'

import { AuthUserContext, withAuthentication } from '../Session/'
import { POSTER_CATEGORIES } from '../../constants/posterSched.js'
//import posterOrder from '../../constants/posterOrder'
import { AiOutlineProject, AiFillStar } from 'react-icons/ai'
import { useParams, useSearchParams } from 'react-router-dom'
import { FirebaseContext } from '../../server/Firebase'
import FilterBar from './FilterBar'
import { NavLink } from 'react-router-dom'
import { UploadPosterSection } from '../Poster/UploadPosterSection.jsx'

const Posters = () => {
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthUserContext)
  const [searchParams] = useSearchParams()
  const [researchAreas, setResearchAreas] = useState(
    [...searchParams].filter((e) => e[0] === 'researchArea').map((e) => e[1])
  )
  const [focusGroups, setFocusGroups] = useState(
    [...searchParams].filter((e) => e[0] === 'focusGroup').map((e) => e[1])
  )
  const [uploadedFiles, setUploadedFiles] = useState(
    [...searchParams].filter((e) => e[0] === 'hasFiles').map((e) => e[1])
  )
  const [presentingTimes, setPresentingTimes] = useState(
    [...searchParams].filter((e) => e[0] === 'time').map((e) => e[1])
  )

  useEffect(() => {
    setResearchAreas(
      [...searchParams].filter((e) => e[0] === 'researchArea').map((e) => e[1])
    )
    setUploadedFiles(
      [...searchParams].filter((e) => e[0] === 'hasFiles').map((e) => e[1])
    )
    setFocusGroups(
      [...searchParams].filter((e) => e[0] === 'focusGroup').map((e) => e[1])
    )
    setPresentingTimes(
      [...searchParams].filter((e) => e[0] === 'time').map((e) => e[1])
    )
  }, [searchParams])

  const postersDb = firebase.usePosters({})

  const presenter = searchParams.get('presenter')
  let posters = postersDb

  if (researchAreas.length) {
    posters = posters.filter((p) => researchAreas.includes(p.researchArea))
  }

  if (focusGroups.length) {
    posters = posters.filter((p) => focusGroups.includes(p.focusGroup))
  }
  if (presentingTimes.length) {
    posters = posters.filter((p) => {
      //poster.day is correct
      if (presentingTimes.includes(p.day.toLowerCase())) return true
      //poster is presenting at a selected time.
      for (const time of presentingTimes) {
        if (p.times?.includes(time)) return true
      }
      return false
    })
  }

  if (presenter) {
    posters = posters.filter((p) =>
      p.fullname.toLowerCase().includes(presenter.toLowerCase())
    )
  }
  const title = searchParams.get('title')
  if (title) {
    posters = posters.filter((p) =>
      p.postertitle.toLowerCase().includes(title.toLowerCase())
    )
  }
  const days = [...searchParams].filter((e) => e[0] === 'day').map((e) => e[1])
  if (days.length) {
    posters = posters.filter((p) => days.includes(p.day.toLowerCase()))
  }

  if (uploadedFiles.includes('PDF')) {
    posters = posters.filter((p) => !!p.pdfUrl)
  }
  if (uploadedFiles.includes('Video')) {
    posters = posters.filter((p) => !!p.videoUrl)
  }

  const number = searchParams.get('number')
  if (number) {
    posters = posters.filter((p) => p.number.toString().includes(number))
  }
  return (
    <div className='flex flex-col items-center py-2'>
      <UploadPosterButton
        posters={postersDb.filter((poster) => poster.email === authUser.email)}
      />

      <h1 className='text-center pb-3 pt-2 text-5xl'>Posters</h1>
      <FilterBar></FilterBar>
      <br />
      <br />
      {Object.keys(POSTER_CATEGORIES).map((section) => (
        <div key={section}>
          <ResearchAreaTemplate
            researchArea={section}
            key={section}
            posterList={posters.filter(
              (poster) => poster.researchArea === section
            )}
          />
        </div>
      ))}
    </div>
  )
}
const UploadPosterButton = ({ posters, posterInfo = {} }) => {
  const [showModal, setShowModal] = useState(false)

  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      <Button onClick={() => handleToggle()} variant='success'>
        Upload your poster
      </Button>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
        title='Upload'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Upload Poster Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {posters.map((poster) => {
            return (
              <div key={poster.id}>
                <UploadPosterSection
                  poster={poster}
                  hasToggle={posters.length > 1}
                  closeModal={handleToggle}
                />
                <br />
                <hr />
                <br />
              </div>
            )
          })}
          <p>
            If you have any issues uploading your poster, please email me
            vgemhelp@gmail.com. Please include your name, poster title, and
            email you used to log into this website.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const Section = ({ section }) => {
  const firebase = useContext(FirebaseContext)
  let researchArea = section
  let posterList = firebase.usePosters({ researchArea })
  return (
    <div>
      <br />
      <h1>{section}</h1>
      <div
        style={{
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'gray',
        }}
      >
        {/* <Carousel>
          {shuffled.map((poster) => {
            return (
              <Carousel.Item key={poster.id}>
              <a
              href={`/posters/${poster.id}`}
              style={{ textAlign: 'center' }}
              >
              <img
              src={poster.src}
              alt='Poster'
              height='400'
              margin='auto'
              ></img>
              </a>
              </Carousel.Item>
              )
            })}
          </Carousel> */}
      </div>
      <br />
      <ResearchAreaTemplate
        researchArea={researchArea}
        posterList={posterList}
      ></ResearchAreaTemplate>
    </div>
  )
}

const ResearchAreaTemplate = ({ researchArea, posterList }) => {
  if (!posterList || posterList.length === 0) {
    return
  }
  return (
    <div className='w-screen sm:px-2'>
      <h4 className={'left pl-2'}>
        <strong>{POSTER_CATEGORIES[researchArea]} </strong>
      </h4>
      <Table>
        <thead>
          <tr>
            <th>Presenter</th>
            <th>Title</th>
            <th className='text-center'>FG</th>
            <th>Day</th>
            <th></th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          {posterList.map((poster, ind) => {
            const name = poster.fullname
            const title = poster.postertitle
            return (
              <tr key={ind}>
                <td>{name}</td>
                <td>
                  <NavLink
                    className='text-blue-500 hover:text-red-500'
                    to={`/posters/${poster.id}`}
                  >
                    {title}
                  </NavLink>
                </td>
                <td className='text-center'>
                  <div className='flex items-center'>
                    {poster.focusGroup}
                    {poster.times?.includes(
                      poster.focusGroup.toLowerCase()
                    ) && (
                      <AiFillStar
                        size='15'
                        color='#43A5BE'
                        title='Will be presenting'
                      />
                    )}
                  </div>
                </td>
                <td>{poster.day}</td>
                <td>{poster.number}</td>
                <td>
                  {poster['pdfUrl'] ? (
                    <AiOutlineProject
                      size='30'
                      color='green'
                      title='Upload Completed'
                    />
                  ) : (
                    <AiOutlineProject
                      size='30'
                      color='#F1948A'
                      title='No Poster'
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <p className='flex items-center'>
        <AiFillStar size='15' color='#43A5BE' title='Will be presenting' />
        {' - '}
        indicates that the poster will be presented at the focus group time.
      </p>
      <br />
    </div>
  )
}

const PostersSectionPre = () => {
  let { sectionId } = useParams()
  return <Section section={sectionId} />
}
const PostersSection = withAuthentication(PostersSectionPre)
export { PostersSection }

export default withAuthentication(Posters)
